<template>
  <div v-if="orderData">
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="Order" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ orderData.id }}</td>
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">{{ orderData.code }}</td>
              </tr>
              <tr>
                <th class="text-left">Airway Bill No</th>
                <td class="text-right" v-if="orderData.air_way_bill">
                  {{ orderData.air_way_bill.air_way_bill_number }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ orderData.orderstatus.name }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th class="text-left">Type</th>
                <td class="text-right">{{ orderData.type }}</td>
              </tr>

              <tr>
                <th class="text-left">Sales Channel</th>
                <td class="text-right">
                  <b-badge variant="info" v-if="orderData.type == 'pos'">
                    Cash On Site
                  </b-badge>
                  <b-badge variant="info" v-else-if="orderData.is_portal">
                    Portal
                  </b-badge>
                  <b-badge variant="info" v-else> InStore </b-badge>
                </td>
              </tr>

              <tr>
                <th class="text-left">Date</th>
                <td class="text-right">{{ orderData.created_at }}</td>
              </tr>
              <tr v-if="delUser">
                <th class="text-left">Last Update Date</th>
                <td class="text-right">{{ orderData.updated_at }}</td>
              </tr>
              <tr v-else>
                <th class="text-left">Deleted Date</th>
                <td class="text-right">{{ orderData.deleted_at }}</td>
              </tr>
              <tr>
                <th class="text-left">Note</th>
                <td class="text-right">{{ orderData.note }}</td>
              </tr>
              <tr>
                <th class="text-left">Address</th>
                <td class="text-right" dir="rtl">{{ orderData.address }}</td>
              </tr>
              <tr>
                <th class="text-left">Transaction ref</th>
                <td class="text-right" dir="rtl">
                  {{
                    orderData.transactions.length > 0
                      ? orderData.transactions[0].transaction_ref
                      : ''
                  }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Cart Id</th>
                <td class="text-right" dir="rtl">
                  {{
                    orderData.transactions.length > 0
                      ? orderData.transactions[0].cart_id
                      : ''
                  }}
                </td>
              </tr>

              <tr v-if="orderData.cart != null">
                <th class="text-left">payment gateway</th>
                <td class="text-right" dir="rtl">
                  <b-badge class="badge btn-primary">
                    {{ orderData.cart.payment_gateway_type }}</b-badge
                  >
                </td>
              </tr>
              <tr v-if="orderData.success_date != null">
                <th class="text-left">Success Date</th>
                <td class="text-right" dir="ltr">
                  {{ orderData.success_date }}
                </td>
              </tr>
              <tr v-if="orderData.delivered_date != null">
                <th class="text-left">Delivered Date</th>
                <td class="text-right" dir="ltr">
                  {{ orderData.delivered_date }}
                </td>
              </tr>
              <tr v-if="orderData.completed_date != null">
                <th class="text-left">Completed Date</th>
                <td class="text-right" dir="ltr">
                  {{ orderData.completed_date }}
                </td>
              </tr>
            
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-1">
        <b-card class="h-100 d-flex flex-wrap" footer-tag="footer">
          <b-button
            block
            :to="'/orders/show/invoice/' + orderData.id"
            variant="outline-primary "
            v-if="
              authPermissions.includes('show-order-invoice') &&
              orderData.transactions.length != 0
            "
            class="mt-1"
          >
            Invoice
          </b-button>
          <div v-if="delUser" class="d-flex flex-wrap justify-content-center">
            <b-button
              block
              v-if="
                authPermissions.includes('update-processing-status') &&
                orderData.orderstatus.slug == 'processing'
              "
              variant="outline-secondary "
              @click="cancelProcessingOrder(orderData.id)"
              class="mt-1"
            >
              Cancel Order
            </b-button>
            <b-button
              block
              v-if="
                authPermissions.includes('check-order-transaction-data') &&
                orderData.orderstatus.slug == 'processing'
              "
              variant="outline-warning "
              @click="checkOrderTransactionData(orderData.id)"
              class="mt-1"
            >
              Refresh Order Transaction
            </b-button>

            <b-button
              block
              v-if="
                authPermissions.includes('show') &&
                orderData.air_way_bill != null
              "
              @click="showAirWayBill()"
              variant="primary "
              class="mt-1"
            >
              Airway Bill
            </b-button>
            <b-modal
              ref="my-modal"
              ok-only
              ok-title="Accept"
              centered
              size="xl"
              title="Airway Bill"
            >
              <object>
                <iframe
                  id="pdfID"
                  type="text/html"
                  width="100%"
                  height="1000px"
                  sc
                  :src="this.shipPDF"
                />
              </object>
            </b-modal>
            <!-- <button >Invoice</button> -->

            <!-- :src="'data:application/pdf;base64,' + this.shipPDF" -->
            <!-- <b-button
              block
              v-if="authPermissions.includes('update-order')"
              :to="{ path: '/orders/Update/' + orderData.id }"
              variant="outline-primary "
              class="mr-1 mt-1"
            >
              
            </b-button> -->

            <!-- <b-button
              block
              v-if="authPermissions.includes('update-order')"
              :to="{ path: '/orders/Update/' + orderData.id }"
              variant="outline-primary "
              class="mr-1 mt-1"
            >
              Edit Order Data
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('update-order')"
              :to="{ path: '/orders/update/products/' + orderData.id }"
              variant="outline-warning "
              class="mr-1 mt-1"
            >
              Edit Order Products
            </b-button> -->
            <b-button
              block
              v-if="
                authPermissions.includes('update-cash-order-status') &&
                orderData.orderstatus.slug == 'pending' &&
                orderData.type == 'pos'
              "
              variant="primary"
              class="mt-1"
              @click="changePortalToDelivered()"
            >
              Update Cash On Site Order
            </b-button>
            <b-button
              block
              v-if="
                authPermissions.includes('update-order-status-to-delivered') &&
                orderData.orderstatus.slug != 'shipped' &&
                orderData.orderstatus.slug != 'processing' &&
                orderData.orderstatus.slug != 'delivered' &&
                orderData.type != 'pos' &&
                orderData.is_portal == true
              "
              @click="changePortalToDelivered()"
              variant="primary"
              class="mt-1"
            >
              Update To Delivered
            </b-button>
            <b-button
              block
              v-if="
                authPermissions.includes('update-order-status') &&
                orderData.orderstatus.slug != 'delivered' &&
                orderData.orderstatus.slug != 'shipped' &&
                orderData.type != 'pos'
              "
              :to="{ path: '/orders/Update/Status/' + orderData.id }"
              variant="primary"
              class="mt-1"
            >
              Edit status
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              @click="deleteOrder(orderData.id)"
              variant="outline-danger"
              class="mt-1"
            >
              Delete
            </b-button>

            <b-button
              block
              v-if="
                authPermissions.includes('show-eContract') &&
                orderData.e_contract != null
               
                
              "
              :to="{ path: '/orders/show/' + orderData.id + '/e-contract' }"
              variant="primary"
              class="mt-1"
            >
              Show E-Contract
            </b-button>
          </div>

          <!-- Add New Order Attachment Modal -->
          <b-modal
            style="height: 700px !important"
            id="modal-add-order-attachments"
            cancel-variant="outline-secondary"
            @ok="storeAttachmentFile"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Add Order Attachment"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="file_type"
                  rules="required"
                >
                  <b-form-group
                    label="File Type"
                    label-for="file_type"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="file_type"
                      v-model="orderAttachmentData.type"
                      :state="orderAttachmentData.type === null ? false : true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="orderFileAttachmentOptions"
                      :selectable="(option) => !option.includes('select_value')"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- file -->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="required"
                >
                  <b-form-group label="File" label-for="file">
                    <input
                      type="file"
                      class="form-control-file"
                      name="file"
                      id="file"
                      ref="file"
                      change
                      @change="fileUploadIndex"
                      accept="application/pdf, image/*"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <label for="textarea-default">Note</label>
                <validation-provider
                  #default="{ errors }"
                  name="note"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="orderAttachmentData.note"
                    placeholder="Note"
                    :state="errors.length > 0 ? false : null"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-modal>
          <!-- Update Orer To Delivered-->
          <b-modal
            style="height: 700px !important"
            ref="modal-status-update-portal"
            cancel-variant="outline-secondary"
            @ok="updateOrderStatusToDelivered"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Change Status"
          >
            <b-row>
              <b-col md="12" v-if="orderData.type == 'pos'">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-group
                    label="Status"
                    label-for="status"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="cashStatusSelect"
                      :state="cashStatusSelect === null ? false : true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="cashStatusOptions"
                      :selectable="(option) => !option.includes('select_value')"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                v-if="
                  cashStatusSelect == 'delivered' || orderData.type != 'pos'
                "
              >
                <b-form-group label="File Type" label-for="file_type">
                  <v-select
                    id="file_type"
                    v-model="orderAttachmentData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                  />
                </b-form-group>
              </b-col>

              <!-- file -->
              <b-col
                md="6"
                v-if="
                  cashStatusSelect == 'delivered' || orderData.type != 'pos'
                "
              >
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="required"
                >
                  <b-form-group label="File" label-for="file">
                    <input
                      type="file"
                      class="form-control-file"
                      name="file"
                      id="file"
                      ref="file"
                      change
                      @change="fileUploadIndex"
                      accept="application/pdf, image/*"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- <b-form-checkbox
            id="checkbox-1"
            v-model="disabled"
            v-show="
              cashStatusSelect.value == 'disabled' ||
              cashStatusSelect.value == 'enabled'
            "
            name="checkbox-1"
            value="1"
            unchecked-value="0"
          >
            Force Disabled
          </b-form-checkbox> -->
          </b-modal>
          <hr />
          <div v-if="delUser" class="d-flex">
            <div class="m-4 w-50">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ orderData.created_at }}
              </h3>
            </div>
            <div class="m-4 w-50">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ orderData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ orderData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Deleted at</h6>
              <h3 class="mb-0">
                {{ orderData.deleted_at }}
              </h3>
            </div>
          </div>
          <template #footer v-if="authPermissions.includes('create_refund')">
            <em>
              <b-row>
                <b-col cols="12" v-if="orderData.refund_id != null">
                  <b-button
                    block
                    variant="outline-warning"
                    @click="
                      $router.push('/orders/refund/show/' + orderData.refund_id)
                    "
                  >
                    Show Refund Data
                  </b-button>
                </b-col>
              </b-row>
            </em>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="12" md="6" class="mb-1">
        <b-card
          v-if="orderData.vendor != null"
          title="Vendor"
          class="h-100 cardH d-flex flex-wrap table-responsive"
        >
          <a
                class="ml-1 position-absolute"
                style="top: 20px; right: 25px"
                :href=" '/Vendor/Show/' + orderData.vendor.id "
                v-if="authPermissions.includes('vendor')"
              >
                <img :src="require('@/assets/images/icons/show.svg')" />
              </a>
          <!-- User Avatar & Action Buttons -->
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ orderData.vendor.id }}</td>
              </tr>
              <tr>
                <th class="text-left">First Name</th>
                <td class="text-right">{{ orderData.vendor.firstname }}</td>
              </tr>
              <tr>
                <th class="text-left">Last Name</th>
                <td class="text-right">{{ orderData.vendor.lastname }}</td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">{{ orderData.vendor.email }}</td>
              </tr>
              <tr>
                <th class="text-left">Phone</th>
                <td class="text-right">
                  <a :href="'tel:' + orderData.vendor.phone" class="h4">{{
                    orderData.vendor.phone
                  }}</a>
                </td>
              </tr>
              <tr>
                <th class="text-left">Store Name</th>
                <td class="text-right">{{ orderData.vendor.storename }}</td>
              </tr>
            </tbody>
          </table>
        </b-card>
        <b-card
          v-else
          title="Vendor"
          class="h-100 cardH text-center d-flex flex-wrap"
          style="display: flex; justify-content: center; align-items: center"
        >
          <h1>Mobile Masr</h1>
        </b-card>
      </b-col>
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="User" class="h-100 d-flex flex-wrap table-responsive">
          
          <a
                class="ml-1 position-absolute"
                style="top: 20px; right: 25px"
                :href=" '/User/Show/' + orderData.user.id "
                v-if="authPermissions.includes('user')"
              >
                <img :src="require('@/assets/images/icons/show.svg')" />
              </a>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ orderData.user.id }}</td>
              </tr>
              <tr>
                <th class="text-left">First Name</th>
                <td class="text-right">{{ orderData.user.firstname }}</td>
              </tr>
              <tr>
                <th class="text-left">Last Name</th>
                <td class="text-right">{{ orderData.user.lastname }}</td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">{{ orderData.user.email }}</td>
              </tr>
              <tr>
                <th class="text-left">Phone 1</th>

                <td class="text-right">
                  <a :href="'tel:' + orderData.phoneone" class="h4">
                    <the-mask
                      mask="#### ### ####"
                      disabled
                      v-model="orderData.phoneone"
                      class="border-0 text-right phonenumber"
                    >
                    </the-mask>
                  </a>
                </td>
              </tr>
              <tr>
                <th class="text-left">Phone 2</th>

                <td class="text-right">
                  <a :href="'tel:' + orderData.phonetwo" class="h4">
                    <the-mask
                      mask="#### ### ####"
                      disabled
                      v-model="orderData.phonetwo"
                      class="border-0 text-right phonenumber"
                    >
                    </the-mask>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col class="mb-1" md="12">
        <b-row>
          <!-- EContract Data -->
          <b-col class="mb-1" md="6">
            <b-card
              title="Customer Details"
              class="h-100 d-flex flex-wrap table-responsive"
            >
              <a
                class="ml-1 position-absolute"
                style="top: 20px; right: 25px"
                @click="enableUpdateCustomerDataAction()"
                v-if="authPermissions.includes('update-eContract-data')"
              >
                <img :src="require('@/assets/images/icons/edit-new.svg')" />
              </a>
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="text-left">Customer Name</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      {{ orderData.e_contract.customer_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Customer Id Number</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      {{ orderData.e_contract.customer_id_number }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Id Front Image</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      <a
                        v-if="orderData.e_contract.id_front_image != null"
                        :href="orderData.e_contract.id_front_image"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Open</a
                      >

                      <!-- <a
                        class="ml-1"
                        @click="
                          enableUpdateIdFrontImage = !enableUpdateIdFrontImage
                        "
                        v-if="authPermissions.includes('update-eContract-data')"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width: 18px; height: 18px"
                        />
                      </a> -->
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Id Back Image</th>
                    <td class="text-right" v-if="orderData.e_contract">
                      <a
                        v-if="orderData.e_contract.id_back_image != null"
                        :href="orderData.e_contract.id_back_image"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Open</a
                      >

                      <!-- <a
                        class="ml-1"
                        @click="
                          enableUpdateIdBackImage = !enableUpdateIdBackImage
                        "
                        v-if="authPermissions.includes('update-eContract-data')"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width: 18px; height: 18px"
                        />
                      </a> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="orderData.e_contract != null">
                <b-button
                  v-if="orderData.e_contract.e_contract_image != null"
                  block
                  v-b-modal.eContractImage
                  variant="primary"
                  class="mr-1 mt-1"
                >
                  EContract Image
                </b-button>
                <b-modal
                  id="eContractImage"
                  ok-only
                  centered
                  size="lg"
                  title="EContract Image"
                >
                  <img
                    width="100%"
                    :src="orderData.e_contract.e_contract_image"
                  />
                </b-modal>
              </div>
            </b-card>
          </b-col>
          <!--Edit EContract Data -->
          <b-col
            class="mb-1"
            md="6"
            v-if="
              (enableUpdateIdFrontImage ||
                enableUpdateIdBackImage ||
                enableUpdateCustomerData) &&
              authPermissions.includes('update-eContract-data')
            "
          >
            <b-card
              title="Update Customer Data"
              class="table-responsive"
              v-if="
                (enableUpdateIdFrontImage ||
                  enableUpdateIdBackImage ||
                  enableUpdateCustomerData) &&
                authPermissions.includes('update-eContract-data')
              "
            >
              <validation-observer ref="simpleRules">
                <b-row>
                  <!-- Customer name -->
                  <b-col md="6">
                    <b-form-group
                      label="Customer Name"
                      label-for="first-name"
                      v-if="enableUpdateCustomerData"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Customer Name"
                        rules="required"
                      >
                        <b-form-input
                          id="first-name"
                          v-model="customerData.customer_name"
                          placeholder="John"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Customer Id Number -->
                  <b-col md="6">
                    <b-form-group
                      v-if="enableUpdateCustomerData"
                      label="Customer Id Number"
                      label-for="customer-id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Customer Id Number"
                        rules="required"
                      >
                        <b-form-input
                          id="customer-id"
                          v-model="customerData.customer_id_number"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Id front picture -->
                  <b-col
                    md="6"
                    v-if="enableUpdateIdFrontImage || enableUpdateCustomerData"
                  >
                    <b-form-group
                      label="Id front picture"
                      label-for="Id front picture"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Id front picture"
                      >
                        <b-form-file
                          @change="ChangeIDFrontImage($event)"
                          v-model="customerData.id_front_image_file"
                          id="ProfilePictureBase642"
                          accept="image/*"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Id back picture -->
                  <b-col
                    md="6"
                    v-if="enableUpdateIdBackImage || enableUpdateCustomerData"
                  >
                    <b-form-group
                      label="Id back picture"
                      label-for="Id back picture"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Id back picture"
                      >
                        <b-form-file
                          @change="ChangeIDBackImage($event)"
                          v-model="customerData.id_back_image_file"
                          id="ProfilePictureBase643"
                          accept="image/*"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-button variant="success" @click="updateEcontractIds" block>
                    Submit</b-button
                  >
                </b-row>
              </validation-observer>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- variants data -->
    <b-row>
      <b-col v-if="orderData.variants.length > 0" class="" cols="12">
        <b-card title="Items List" class="table-responsive">
          <b-table
            striped
            hover
            :items="orderData.variants"
            :fields="fields"
            class=""
          >
            <template #cell(show)="items">
              <b-button
                style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                class="btn-icon rounded-circle"
                :to="{
                  path:
                    (items.item.is_new ? '/New' : '/Used') +
                    '/ShowProduct/variant/show/' +
                    items.item.id,
                }"
                variant="success"
              >
                <feather-icon
                  style="width: 18px; height: 18px"
                  icon="EyeIcon"
                />
              </b-button>
              <!--:to="{name: 'OrderShowCompareReports', params: { selling: items.value[0].type == 'selling' ? items.value[0].serial_number : items.value[1].serial_number, buying: items.value[1].type == 'buying' ? items.value[1].serial_number : items.value[0].serial_number} }"-->

              <!-- <h1 v-else>-</h1> -->
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(is_new)="data">
              <b-badge :variant="data.value ? 'success' : 'info'">
                {{ data.value ? 'New' : 'Used' }}
              </b-badge>
            </template>
            <template #cell(identical)="data">
              <img
                :src="
                  data.value == false
                    ? require('@/assets/images/remove.png')
                    : require('@/assets/images/check.png')
                "
                alt="alternate"
                title="tit"
                width="20px"
                height="20px"
              />
            </template>
          </b-table>
        </b-card>
        <b-modal
          size="lg"
          id="modal-scrollable"
          scrollable
          :title="'Compare Result - (Identical: ' + identicalType + ')'"
          cancel-title="Close"
          cancel-variant="outline-secondary"
          class="d-flex flex-wrap"
        >
          <b-table striped hover :items="compareItems" :fields="compareFields">
            <template #cell(identical)="data">
              <img
                :src="
                  data.value == false
                    ? require('@/assets/images/remove.png')
                    : require('@/assets/images/check.png')
                "
                alt="alternate"
                title="tit"
                width="20px"
                height="20px"
              />
            </template>
          </b-table>
        </b-modal>
      </b-col>
      <b-col v-if="orderData.bundles.length > 0" cols="12">
        <b-card title="Bundles">
          <b-table-simple hover small responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th colspan="4">Bundle</b-th>
                <b-th colspan="1">Bundle Qty</b-th>
                <b-th colspan="1">Status</b-th>
                <b-th colspan="5">Item</b-th>
                <b-th colspan="1">Item Qty</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in orderData.bundles" :key="index">
              <b-tr>
                <b-th colspan="4">
                  {{ item.title }}
                </b-th>
                <b-td colspan="1">
                  {{ item.quantity }}
                </b-td>
                <b-td colspan="1">
                  <b-badge variant="primary">
                    {{ item.item_status_slug }}
                  </b-badge>
                </b-td>
                <b-th colspan="5">
                  <div v-for="(itme, index) in item.variants" :key="index">
                    {{ itme.name }}
                  </div>
                </b-th>
                <b-td colspan="1">
                  <div v-for="(itme, index) in item.variants" :key="index">
                    {{ itme.quantity }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
    <!-- princing data -->
    <b-row>
      <b-col cols="12" md="12">
        <b-card title="Order Total" class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Total</th>
                <th class="text-right">{{ orderData.total }}</th>
              </tr>
              <tr>
                <th class="text-left">Shipping Price</th>
                <td class="text-right">{{ orderData.shipping_price }}</td>
              </tr>
              <tr>
                <th class="text-left">Tax</th>
                <td class="text-right">{{ orderData.tax }}</td>
              </tr>
              <tr>
                <th class="text-left">COD</th>
                <td class="text-right">{{ orderData.cod }}</td>
              </tr>

              <tr>
                <th class="text-left">Discounte</th>
                <td class="text-right">{{ discount }}</td>
              </tr>

              <tr class="bg-secondary text-light">
                <th class="text-left">Total Paid</th>
                <th class="text-right">{{ orderData.total_paid }}</th>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Transactions">
          <b-table
            id="TransactionsTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :items="orderData.transactions"
            :fields="fieldsTransactions"
          >
            <template #cell(status)="data">
              <b-badge variant="primary">{{ data.value }}</b-badge>
            </template>
            <template #cell(actions)="items">
              <div class="demo-inline-spacing">
                <!-- show payout -->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  class="btn-icon rounded-circle"
                  v-if="authPermissions.includes('show')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/transaction/show/' + items.item.id }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card title="Order Attachments">
          <b-button
            v-if="authPermissions.includes('add-order-attachment')"
            style="top: 10px; right: 20px"
            class="position-absolute"
            target="_blank"
            variant="none"
            type="submit"
            v-b-modal.modal-add-order-attachments
          >
            <img :src="require('@/assets/images/icons/add-new.svg')" />
          </b-button>

          <b-alert
            v-height-fade.appear
            fade
            :show="storeAttachmentShowDismissibleAlert"
            @dismissed="storeAttachmentShowDismissibleAlert = false"
            variant="danger"
          >
            <h4 class="alert-heading">Alert</h4>
            <div class="alert-body">
              <ul
                v-for="(values, index) in storeAttachmentErrorsBack"
                :key="index"
              >
                <li v-for="(value, index) in values" :key="index">
                  {{ value }}
                </li>
              </ul>
            </div>
          </b-alert>

          <b-table
            v-if="orderData.order_attachments.length != 0"
            striped
            hover
            :items="orderData.order_attachments"
            :fields="orderAttachmentFields"
            class=""
          >
            <!-- <template #cell(name)="items">
                  <a       :href="items.item.name"
                      target="_blank">

                    <img
                      style="width: 24px;height: 21px;"
                
                      :src="require('@/assets/images/icons/photo-up.svg')"
                    />
                  </a>
                </template> -->
            <template #cell(actions)="items">
              <div class="d-flex">
                <!-- delete Order -->
                <b-button
                  :href="items.item.name"
                  target="_blank"
                  variant="none"
                  type="submit"
                >
                  <img :src="require('@/assets/images/icons/photo-up.svg')" />
                </b-button>
                <!-- <b-button
                      v-if="authPermissions.includes('delete')"
                      variant="none"
                      type="submit"
                      @click="deleteAttachmentFile(items.item.id)"
                    >
                      <img
                        :src="require('@/assets/images/icons/delete-new.svg')"
               
                      />
              
                    </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <!-- AWB Logger Data -->
    <b-card
      v-if="shipData != null"
      title="Airway Bill Status "
      class="d-flex flex-wrap"
    >
      <b-row>
        <b-col cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in shipData.TrackingLogDetails"
              :key="index"
              variant="primary"
            >
              <div class="">
                <h5>{{ item.Remarks }}</h5>
                <p>{{ item.ActivityDate }}</p>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
    <!-- Order Logger -->
    <b-card title="Notes" class="d-flex flex-wrap">
      <b-row>
        <b-col cols="12">
          <label for="textarea-default">Note</label>
          <b-form-textarea
            id="textarea-default"
            v-model="loggerNote.note"
            placeholder="Note"
            rows="3"
          />
          <b-button
            variant="primary"
            class="mt-1"
            @click="storeLogger(orderData.id)"
          >
            Submit
          </b-button>
        </b-col>
        <b-col class="mt-3" cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in orderData.logers"
              :key="index"
              :variant="item.admin != null ? 'primary' : 'success'"
            >
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6>{{ item.note }}</h6>
              </div>
              <b-media v-if="item.admin != null">
                <template #aside>
                  <b-avatar :src="item.admin.profile_picture" />
                </template>
                <h6>{{ item.admin.firstname + ' ' + item.admin.lastname }}</h6>
                <p>{{ item.admin.role }}</p>
              </b-media>
              <b-media v-else>
                <template #aside>
                  <!-- <b-avatar :src="item.vendor.profile_picture" /> -->
                </template>
                <!-- <h6>{{item.vendor.storename}}</h6> -->
              </b-media>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { TheMask } from 'vue-the-mask'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1.0, maximum-scale=2.0, user-scalable=yes',
      },
    ],
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    TheMask,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      required,
      authPermissions: [],
      orderData: null,
      errors_back: [],
      storeAttachmentErrorsBack: [],
      storeAttachmentShowDismissibleAlert: false,
      showDismissibleAlert: false,
      id: 0,
      status: '',
      area: '',
      delUser: true,
      imgIndex: null,
      profileArr: [],

      loggerNote: {
        note: '',
      },
      discount: '',

      compareItems: [],
      compareFields: [
        {
          key: 'name',
          label: 'name',
        },
        {
          key: 'selling',
          label: 'selling',
        },
        {
          key: 'buying',
          label: 'buying',
        },
        {
          key: 'identical',
          label: 'identical',
        },
      ],
      fields: [
        {
          key: 'name',
          label: 'Product',
        },
        {
          key: 'sku',
          label: 'SKU',
        },
        {
          key: 'quantity',
          label: 'Qty',
        },
        {
          key: 'vendor_price',
          label: 'Vendor Price',
        },
        {
          key: 'margin',
          label: 'margin',
        },
        {
          key: 'insurance_fees',
          label: 'Insurance Fees',
        },
        {
          key: 'price',
          label: 'Price',
        },

        {
          key: 'total',
          label: 'Total',
        },

        {
          key: 'item_status_slug',
          label: 'Item Status In Order',
        },
        {
          key: 'is_new',
          label: 'Type',
        },
        // {
        //   key: 'identical',
        //   label: 'identical'
        // },
        // {
        //   key: 'CompareReports',
        //   label: 'Compare Reports'
        // }
        // },
        {
          key: 'show',
          label: 'show',
        },
      ],
      orderAttachmentFields: [
        {
          key: 'type',
          label: 'Attachment Type',
        },
        // {
        //   key: 'name',
        //   label: 'File',
        // },

        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      // table fildes
      fieldsTransactions: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'transaction_ref',
          label: 'transaction ref',
        },
        {
          key: 'platform_type',
          label: 'Platform Type',
        },
        {
          key: 'customer_name',
          label: 'customer name',
        },

        {
          key: 'customer_data',
          label: 'customer (email/phone)',
        },

        {
          key: 'transaction_type',
          label: 'transaction type',
        },
        {
          key: 'payment_method',
          label: 'payment method',
        },
        {
          key: 'amount',
          label: 'amount',
        },
        {
          key: 'remaining_amount',
          label: 'remaining amount',
        },
        {
          key: 'payment_commission',
          label: 'payment commission',
        },
        {
          key: 'transaction_fees',
          label: 'transaction fees',
        },
        {
          key: 'created_at',
          label: 'created at',
        },
        {
          key: 'updated_at',
          label: 'updated at',
        },
        {
          key: 'actions',
        },
      ],
      itemsTransactions: [],
      identicalType: false,
      shipData: null,
      shipPDF: null,
      cashStatusOptions: ['delivered', 'canceled'],
      orderFileAttachmentOptions: [
        'invoice',
        'agreement',
        'airwaybill',
        'signed_airwaybill',
        'refund_airwaybill',
      ],
      cashStatusSelect: null,
      customerData: {
        customer_name: null,
        customer_id_number: null,
        id_front_image: null,
        id_back_image: null,
        id_front_image_file: null,
        id_back_image_file: null,
      },
      enableUpdateCustomerData: false,
      enableUpdateIdFrontImage: false,
      enableUpdateIdBackImage: false,
      orderAttachmentData: {
        file: null,
        type: null,
        note: null,
      },
    }
  },

  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    getBillData() {
      axios
        .get(
          'orders/awb/' +
            this.orderData.air_way_bill.air_way_bill_number +
            '/tracking/get'
        )
        .then((result) => {
          this.shipData = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    changePortalToDelivered() {
      this.orderAttachmentData.type = 'signed_airwaybill'
      this.$refs['modal-status-update-portal'].show()
    },
    showAirWayBill() {
      if (this.shipPDF == null) {
        this.getPDF()
      } else {
        this.showModal()
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    async getPDF() {
      await axios
        .get(
          'orders/awb/' +
            this.orderData.air_way_bill.air_way_bill_number +
            '/pdf/generate'
        )
        .then((result) => {
          this.shipPDF =
            'data:application/pdf;base64,' + result.data.data.ReportDoc
          this.showModal()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    async cancelProcessingOrder() {
      await this.$bvModal
        .msgBoxConfirm('Please confirm that you want to cancel Order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get(
                'orders/' +
                  this.orderData.id +
                  '/changeProcessingOrderToCanceled'
              )
              .then((result) => {
                this.orderData = result.data.data
                if (this.orderData.deleted_at != null) {
                  this.delUser = false
                } else {
                  this.delUser = true
                }
              })
              .catch((err) => {
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    async checkOrderTransactionData() {
      await this.$bvModal
        .msgBoxConfirm('Please confirm that you want to Reresh Order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.errors_back.length = 0
            this.showDismissibleAlert = false

            axios
              .get(
                'orders/' +
                  this.orderData.id +
                  '/checkProcessingOrderTransaction'
              )
              .then((result) => {
                this.orderData = result.data.data
                // //console.log(this.orderData)
                if (this.orderData.air_way_bill != null) {
                  this.getBillData()
                  // this.getPDF()
                }
                if (this.orderData.deleted_at != null) {
                  this.delUser = false
                } else {
                  this.delUser = true
                }

                this.orderData.orderstatus = result.data.data.orderstatus
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Order Data Checked Success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })

              .catch((err) => {
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    validateOrderAttachmentData() {
      if (this.orderAttachmentData.file == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'must upload attachment file ',
        })
        return
      }
      if (this.orderAttachmentData.type == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'invalid selected type ',
        })
        return
      }
    },
    // store function of (store button) Order
    async storeAttachmentFile() {
      this.storeAttachmentErrorsBack = []
      this.storeAttachmentShowDismissibleAlert = false
      let formData = new FormData()
      await this.validateOrderAttachmentData()

      formData.append('note', this.orderAttachmentData.note)
      formData.append('type', this.orderAttachmentData.type)
      formData.append('file', this.orderAttachmentData.file)

      axios
        .post('orders-attachments/' + this.orderData.id + '/store', formData)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          console.log(result.data.data)
          this.orderData.order_attachments.push(result.data.data)
          console.log(this.orderData.order_attachments)
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.storeAttachmentErrorsBack.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.storeAttachmentErrorsBack.push({
                error: err.response.data.data,
              })
            } else {
              this.storeAttachmentErrorsBack = err.response.data.data
            }
            this.storeAttachmentShowDismissibleAlert = true
          } else {
            this.storeAttachmentErrorsBack = []
            this.storeAttachmentErrorsBack.push({
              error: 'internal server error',
            })
            this.storeAttachmentShowDismissibleAlert = true
          }
        })
    },
    // delete function of (delete button) Order
    deleteAttachmentFile(id) {
      //console.log('delete')
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete This File.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('orders-attachments/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    updateCashOnSiteStatus() {
      let formData = new FormData()
      if (this.cashStatusSelect == 'delivered') {
        if (this.orderAttachmentData.file == null) {
          this.showDismissibleAlert = true
          this.errors_back.push({
            error: 'must upload attachment file ',
          })
          return
        }
        if (this.orderAttachmentData.type == null) {
          this.showDismissibleAlert = true
          this.errors_back.push({
            error: 'invalid selected type ',
          })
          return
        }
        formData.append('type', this.orderAttachmentData.type)
        formData.append('file', this.orderAttachmentData.file)
        // data.file = this.orderAttachmentData.file
        console.table(Object.fromEntries(formData))
      }
      axios
        .post(
          'orders/' +
            this.id +
            '/cashOnSite/' +
            this.cashStatusSelect +
            '/update-status',
          formData
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log(result.data.data)
          this.orderData = result.data.data
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    updateOrderStatusToDelivered() {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to Update Order To Delivered.',
          {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            let formData = new FormData()
            if (this.orderAttachmentData.file == null) {
              this.showDismissibleAlert = true
              this.errors_back.push({
                error: 'must upload attachment file ',
              })
              return
            }
            if (this.orderAttachmentData.type == null) {
              this.showDismissibleAlert = true
              this.errors_back.push({
                error: 'invalid selected type ',
              })
            }

            formData.append('type', this.orderAttachmentData.type)
            formData.append('file', this.orderAttachmentData.file)
            // data.file = this.orderAttachmentData.file
            console.table(Object.fromEntries(formData))

            var url = null

            if (this.orderData.type == 'pos') {
              url =
                'orders/' +
                this.id +
                '/cashOnSite/' +
                this.cashStatusSelect +
                '/update-status'
            } else {
              url = 'orders/' + this.id + '/ChangeToDelivered'
            }
            axios
              .post(url, formData)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                console.log(result.data.data)
                this.orderData = result.data.data
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    fetchData() {
      axios
        .get('orders/' + this.id)
        .then((result) => {
          this.orderData = result.data.data
          // //console.log(this.orderData)
          if (this.orderData.air_way_bill != null) {
            this.getBillData()
            this.shipPDF = this.orderData.air_way_bill.air_way_bill_pdf
            // this.getPDF()
          }
          if (this.orderData.deleted_at != null) {
            this.delUser = false
          } else {
            this.delUser = true
          }

          //
          if (this.orderData.coupon != null) {
            this.discount = this.orderData.coupon.percentage
          } else {
            this.discount = '-'
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    selectRow(index, identical) {
      this.identicalType = identical
      this.compareItems = []
      for (let inde in this.orderData.variants[index].compareResult) {
        this.compareItems.push({
          name: Object.keys(
            this.orderData.variants[index].compareResult[inde]
          )[0],
          selling:
            this.orderData.variants[index].compareResult[inde][
              Object.keys(this.orderData.variants[index].compareResult[inde])[0]
            ].selling,
          buying:
            this.orderData.variants[index].compareResult[inde][
              Object.keys(this.orderData.variants[index].compareResult[inde])[0]
            ].buying,
          identical:
            this.orderData.variants[index].compareResult[inde][
              Object.keys(this.orderData.variants[index].compareResult[inde])[0]
            ].identical,
        })
      }
    },
    storeLogger(id) {
      axios
        .post('orders/' + id + '/logger/store', this.loggerNote)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    enableUpdateCustomerDataAction() {
      this.enableUpdateCustomerData = !this.enableUpdateCustomerData
      if (
        this.enableUpdateCustomerData == true &&
        this.orderData.e_contract != null
      ) {
        this.customerData.customer_id_number =
          this.orderData.e_contract.customer_id_number
        this.customerData.customer_name =
          this.orderData.e_contract.customer_name
      }
    },
    ChangeIDFrontImage(e) {
      // this.id_front_image_file = e
      const image = e.target.files[0]
      console.log(e)
      if (e != null && image != null) {
        var fileReader = new FileReader()
        let vm = this
        fileReader.onload = (e) => {
          this.customerData.id_front_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
          // console.log(this.id_front_image)
        }
        fileReader.readAsDataURL(image)
      }
    },
    ChangeIDBackImage(e) {
      // this.id_back_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        fileReader.onload = (e) => {
          this.customerData.id_back_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },

    updateEcontractIds() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post('orders/' + this.id + '/e-contract/update', {
              id_front_image: this.customerData.id_front_image,
              id_back_image: this.customerData.id_back_image,
              customer_id_number: this.customerData.customer_id_number,
              customer_name: this.customerData.customer_name,
            })
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.orderData.e_contract = result.data.data
              this.enableUpdateCustomerData = false
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    deleteOrder(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Order.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('orders/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    fileUploadIndex(e) {
      this.orderAttachmentData.file = e.target.files[0]
      console.log(this.orderAttachmentData)
    },
  },
}
</script>

<style scoped>
/* #card {
    display: flex !important;
    flex-wrap: wrap !important;
} */
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.cardH {
  margin-bottom: 0px;
}
.phonenumber {
  font-weight: bolder !important;
  color: #0a0a0a;
  background: none;
}
</style>
